import { Modal } from 'bootstrap'

document.addEventListener("DOMContentLoaded", function () {
    document.getElementById("confirmButton").addEventListener("click", function () {
        // 遷移先のURLを取得
        const redirectUrl = document.getElementById("confirmButton").dataset.redirect;

        const myModalEl = document.getElementById('myModal');
        const modalInstance = Modal.getInstance(myModalEl);
        modalInstance.hide();

        // 画面遷移
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
        
    });
});
